import React from "react";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Values from "./components/Values";
//import Customers from "./components/Customers";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";

import "./App.css";

function App() {
	return (
		<div className="App">
			<Header />
			<Hero />
			<section id="services">
				<Services />
			</section>
			<section id="values">
				<Values />
			</section>
			<section id="contact-us">
				<ContactUs />
			</section>
			{/*<Customers />*/}
			<Footer />
		</div>
	);
}

export default App;
