import React, { useEffect, useRef } from "react";

import "./../componentStyles/hero.css";

import heroImage from "../images/MC_headerimage.png";

const Hero = () => {
	const heroRef = useRef(null);
	const imageRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (heroRef.current && imageRef.current) {
				const heroRect = heroRef.current.getBoundingClientRect();
				const heroHeight = heroRect.height;
				const scrollPosition = window.scrollY;

				const scrollPercentage = Math.min(
					scrollPosition / (heroHeight * 0.75),
					1
				);

				const easedOpacity = 1 - Math.pow(scrollPercentage, 2);

				imageRef.current.style.opacity = Math.max(0, easedOpacity);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleContactClick = () => {
		const contactSection = document.getElementById("contact-us");
		if (contactSection) {
			contactSection.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<section className="hero" ref={heroRef}>
			<div className="hero-content">
				<h1>MORSE & CODE</h1>
				<h2>Your guide to the world of corporate digitalization.</h2>
				<p>
					We are a leading partner with many years of experience in the areas of
					analysis, digitalization, and corporate systems architecture.
				</p>
				<button className="cta-button" onClick={handleContactClick}>
					CONTACT US
				</button>
			</div>
			<div className="hero-image-container">
				<img
					ref={imageRef}
					src={heroImage}
					alt="Business professionals shaking hands"
					className="hero-image"
				/>
			</div>
		</section>
	);
};

export default Hero;
