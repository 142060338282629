import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./../componentStyles/contactus.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    message: "",
    name: "",
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    const composedMessage = `${formData.name}\n${formData.email}\n${formData.message}`;

    emailjs
      .send(
        "service_anvwqcg",
        "template_refr26h",
        {
          to_email: "info@morsecode.cz",
          from_name: formData.name,
          from_email: formData.email,
          message: composedMessage,
        },
        "qZeIhlbuizw6eh-AR"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitMessage("Message sent successfully!");
          setFormData({ message: "", name: "", email: "" });
        },
        (error) => {
          console.log(error.text);
          setSubmitMessage("Failed to send message. Please try again.");
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="contact-form-container">
      <h2>CONTACT US</h2>
      <p className="contact-description">
        Interested or just want to know more? Feel free to reach out!
      </p>
      <form onSubmit={handleSubmit} className="contact-form">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your message*"
          rows="4"
          required
        />
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name*"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Mail*"
          required
        />
        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? "SENDING..." : "SEND"}
        </button>
      </form>
      {submitMessage && <p className="submit-message">{submitMessage}</p>}
    </div>
  );
};

export default ContactUs;
