import React, { useState } from "react";
import Slider from "react-slick";
import "./../componentStyles/values.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ServiceCard = ({
	title,
	description,
	isActive,
	isHovered,
	onHover,
	onLeave,
}) => (
	<div
		className={`value-card ${isActive ? "active" : ""} ${
			isHovered ? "hovered" : ""
		}`}
		onMouseEnter={onHover}
		onMouseLeave={onLeave}
	>
		<h3>{title}</h3>
		<p>{description}</p>
	</div>
);

const Services = () => {
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);

	const values = [
		{
			title: "Reliability",
			description:
				"Trust is the foundation of all our communication. We understand that without the trust of our clients, we could not operate. We believe that the trust our clients place in us is a commitment not only to meet but to exceed their expectations.",
		},
		{
			title: "Responsibility",
			description:
				"In our company, we understand responsibility as a key value for all our activities. We are committed to adhering to the highest standards in the management and execution of IT projects, which includes not only strict adherence to deadlines and budgets but also a responsible approach to environmental impact and data security.",
		},
		{
			title: "Human Approach",
			description:
				"We believe that technology exists for people, which is why a human approach is at the center of everything we do. We strive to create an environment where clients feel comfortable. We are convinced that a personal approach and empathy lead to better results and stronger, more lasting relationships.",
		},
		{
			title: "Professionalism",
			description:
				"Professionalism is the cornerstone of our corporate culture. It is evident in every aspect of our work, from the first contact with the client to the final delivery of the project. Our team consists of experts who are highly qualified and continuously educate themselves to keep pace with the latest technologies and industry standards.",
		},
		{
			title: "Innovation",
			description:
				"Innovation is the driving force of our company. We constantly seek new ways to improve our technologies, processes, and services. We believe that innovative approaches bring revolutionary solutions that help our clients achieve their goals more effectively.",
		},
	];

	const settings = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "60px",
		slidesToShow: 3,
		speed: 500,
		focusOnSelect: true,
		initialSlide: 0,
		beforeChange: (current, next) => setActiveIndex(next),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					centerPadding: "40px",
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerPadding: "30px",
				},
			},
		],
	};

	return (
		<section className="values">
			<h2>VALUES</h2>
			<Slider {...settings}>
				{values.map((value, index) => (
					<ServiceCard
						key={index}
						title={value.title}
						description={value.description}
						isActive={index === activeIndex}
						isHovered={index === hoveredIndex}
						onHover={() => setHoveredIndex(index)}
						onLeave={() => setHoveredIndex(null)}
					/>
				))}
			</Slider>
		</section>
	);
};

export default Services;
