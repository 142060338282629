import React, { useState } from "react";
import logo from "../images/MC_logo-1.png";
import "./../componentStyles/header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsMenuOpen(false); // Close menu after clicking
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo">
          <img src={logo} alt="Morse & Code Logo" className="mc-logo" />
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        <nav className={`navigation ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <button onClick={() => handleNavClick("contact-us")}>
                CONTACT US
              </button>
            </li>
            <li>
              <button onClick={() => handleNavClick("values")}>VALUES</button>
            </li>
            <li>
              <button onClick={() => handleNavClick("services")}>
                SERVICES
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
