import React, { useState } from "react";
import Slider from "react-slick";
import "./../componentStyles/services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ServiceCard = ({
	title,
	description,
	isActive,
	isHovered,
	onHover,
	onLeave,
}) => (
	<div
		className={`service-card ${isActive ? "active" : ""} ${
			isHovered ? "hovered" : ""
		}`}
		onMouseEnter={onHover}
		onMouseLeave={onLeave}
	>
		<h3>{title}</h3>
		<p>{description}</p>
	</div>
);

const Services = () => {
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);

	const services = [
		{
			title: "Business Consultancy",
			description:
				"Our expert Business Consultancy services provide strategic guidance to navigate complex market challenges. We offer tailored solutions to optimize your operations, enhance decision-making processes, and drive sustainable growth in an ever-changing business landscape.",
		},
		{
			title: "Business Process Outsourcing",
			description:
				"Streamline your operations and focus on core competencies with our Business Process Outsourcing services. We manage non-core functions efficiently, reducing costs and improving overall productivity while maintaining high-quality standards across your organization.",
		},
		{
			title: "Digitization Transformation",
			description:
				"Embrace the digital future with our Digitization Transformation services. We help businesses leverage cutting-edge technologies to modernize processes, enhance customer experiences, and create new revenue streams, ensuring your company stays competitive in the digital age.",
		},
		{
			title: "System Architecture",
			description:
				"Our System Architecture services design robust, scalable, and efficient IT infrastructures tailored to your specific needs. We create blueprints for seamless integration of hardware, software, and network components, ensuring optimal performance and future-proofing your technology investments.",
		},
		{
			title: "Process Optimization",
			description:
				"Maximize efficiency and effectiveness with our Process Optimization services. We analyze your existing workflows, identify bottlenecks, and implement data-driven improvements to streamline operations, reduce waste, and boost overall productivity across your organization.",
		},
	];

	const settings = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "60px",
		slidesToShow: 3,
		speed: 500,
		focusOnSelect: true,
		initialSlide: 0,
		beforeChange: (current, next) => setActiveIndex(next),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					centerPadding: "40px",
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerPadding: "30px",
				},
			},
		],
	};

	return (
		<section className="services">
			<h2>SERVICES</h2>
			<Slider {...settings}>
				{services.map((service, index) => (
					<ServiceCard
						key={index}
						title={service.title}
						description={service.description}
						isActive={index === activeIndex}
						isHovered={index === hoveredIndex}
						onHover={() => setHoveredIndex(index)}
						onLeave={() => setHoveredIndex(null)}
					/>
				))}
			</Slider>
		</section>
	);
};

export default Services;
